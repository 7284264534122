<template>
<div style="margin:180px">正在前往支付宝,请稍后..</div>
</template>
<script>
export default{
    data() {
    return {
      content: "",    
    };
  },
  methods: {

  },
   created() {
    this.content = this.$route.query.content;
    //跳转支付宝
    const div = document.createElement('div') 
    div.innerHTML = this.content
    document.body.appendChild(div)
    document.forms[0].submit() //重要，这个才是点击跳页面的核心
   }

}

</script>


